export function localStorageSet(name, data){
    const obj = {
      data,
      // expire: new Date().getTime() + 1000 * 60 * 60 * 24 * 10
  };
  
  localStorage.setItem(name, JSON.stringify(obj));

}

export function localStorageGet(name) {
  const storage = localStorage.getItem(name);
  // const time = new Date().getTime();
  let result = null;
  if (storage) {
    const obj = JSON.parse(storage);
    // if (time < obj.expire) {
    //   result = obj.data;
    // } else {
    //   localStorage.removeItem(name);
    // }
    result = obj.data;
  }
  return result;

}