import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Pagepath: '',
    Livepath:'',
    PageId: 0,
    CommonPagepath: '',
    Livelistpath:''
  },
  getters: {
  },
  mutations: {
    savePagepath(state, Pagepath) {
      state.Pagepath = Pagepath
    },
    saveLivepath(state, Livepath) {
      state.Livepath = Livepath
    },
    savePageId(state, PageId) {
      state.PageId = PageId
    },
    saveCommonPageId(state, CommonPagepath) {
      state.CommonPagepath = CommonPagepath
    },
    saveLivelistpath(state, Livelistpath) {
      state.Livelistpath = Livelistpath
    }

  },
  actions: {
  },
  modules: {
  }
})
