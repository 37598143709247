import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
import '@vant/touch-emulator';
import { localStorageGet } from '@/utils/common'

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
// 引入语言文件
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = new VueI18n({
  locale: localStorageGet('language')  || 'zh', // 设置默认语言
  fallbackLocale: 'zh', // 设置备用语言
  messages: loadLocaleMessages()
});

import 'amfe-flexible'
Vue.config.productionTip = false

import { Icon,Swipe, SwipeItem,Button,Popup,Form,Field,Col, Row,NoticeBar,Uploader,Tab, Tabs, DropdownMenu, DropdownItem,Switch,RadioGroup, Radio ,ActionSheet  } from 'vant';
Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Col);
Vue.use(Row);
Vue.use(NoticeBar);
Vue.use(Uploader);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Switch);
Vue.use(Form);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(ActionSheet);

function adjustRem() {
  const baseWidth = 750; // 设计稿宽度
  const baseRem = 50; // 基准 rootValue
  const maxWidth = 1200; // 最大宽度限制

  const actualWidth = Math.min(document.documentElement.clientWidth, maxWidth);
  const actualRem = (actualWidth / baseWidth) * baseRem;

  if (window.innerWidth > 500) {
    document.documentElement.style.fontSize = `${actualRem}px`;
  } else {
    document.documentElement.style.fontSize = `${actualRem * 2}px`;
  }
}

// 初始化
adjustRem();

// 为了处理屏幕大小变化事件
window.addEventListener('resize', adjustRem);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
